<script>
import axios from 'axios';
import * as signalR from "@microsoft/signalr";
import { setupConnection } from "./js/from-server";
import streamPublisher from "./js/stream-publisher";
import Recorder from "../recorder/Recorder.vue";
import Uploader from "../uploader/Uploader.vue";
import { write } from 'clipboardy';

var wsconn = new signalR.HubConnectionBuilder()
  .withUrl(process.env.VUE_APP_SIGNALR_URL, signalR.HttpTransportType.WebSockets)
  .configureLogging(signalR.LogLevel.None).build();

import Profiles from "./_Profiles.vue";
import Messages from "./_Messages.vue";
import Send from "./_Send.vue";
import Title from "./_Title.vue";
import Donate from "./_Donate.vue";

export default {
  setup() {
    return {
    };
  },
  data() {
    return {
      isServerSide: false,
      idSessionGroup: null,
      wsconnSetup: false,
      hasMediaAccess: false,
      moderatorConnectionId: null,
      connections: [],
      connectionConfig: null,
      tracksGroupStream: [],
      tracksRemoteConnections: [],
      groupStream: null,
      myTrack: null,
      channelsCounter: 0,
      listenersCounter: 0,
      speakersCounter: 0,
      makingOffer: false,

      //Recorder
      ac: null,
      destination: null,
      deviceLabel: '',
      visibleConnection: false,
      visibleModeratorsToolset: false,
      visibleMembersToolset: false,
      hasCallProcess: false,
      hasRecord: false,
      recorderState: 'off',

      //Stream
      streamSharing: false,
      streamSharingPublisher: null,
      streamSharingId: null,
      streamSharingUrl: null,
      streamSharingWhip: null,
      streamConfigAllowChat: true,
      streamConfigAllowDonations: false,

      //Post create
      buttonCreateDisabled: false,
      buttonCreateText: 'Upload',
      title: '',
      message: '',

      //Episode data
      episode: {
        type: Object,
        default: function () {
          return {
            id: '',
            title: '',
            short: '',
            urlAvatar: '',
          }
        }
      }
    };
  },
  components: {
    Profiles,
    Messages,
    Send,
    Title,
    Recorder,
    Donate,
    Uploader,
  },
  methods: {
    accessMedia(serverSide) {
      if(this.myTrack !== null)
        return;
      this.isServerSide = serverSide;
      this.$refs.postRecorder.accessToMedia();
    },
    readyStreamAccess(stream) {
      console.log('Ready stream');
      this.hasMediaAccess = true;
      var track = stream.getAudioTracks()[0];
      this.myTrack = track;
      console.log("Ready to connect...");
    },
    readyRecordFile(state) {
      console.log("Recorder new state: ", state);
      this.hasRecord = state;
    },
    initiateOffer (connectionId) {
      console.log("Init Offer...");
      var connection = this.getConnection(connectionId);

      connection.createOffer({ offerToReceiveAudio : true, iceRestart: false })
      .then(offer => {
          console.log('WebRTC: Created Offer. Description after offer:');
          console.log(offer);
          connection.setLocalDescription(offer)
          .then(() => {
            console.log('WebRTC: set Local Description: ');
            console.log('connection before sending offer ', connection);
              setTimeout(() => {
                  this.sendHubSignal(JSON.stringify({ "sdp": connection.localDescription }), connectionId);
              }, 1000);
          })
          .catch(err => console.error('WebRTC: Error while setting local description', err));
      }).catch(err => console.error('WebRTC: Error while creating offer', err));
    },
    getConnection(connectionId) {
      console.log("getConnection");
      if (this.connections[connectionId]) {
        console.log("WebRTC: connections partner client exist");
          return this.connections[connectionId];
      }
      else {
        console.log("WebRTC: initialize new connection. My side: " + this.isServerSide);
          var connection = this.createPeerConnection(connectionId);
          this.connections[connectionId] = connection;
          if (this.isServerSide) {
              this.applyToOldFromNew(connection, connectionId);
          }
          return connection;
      }
    },

    //Peer connection
    getConnectionConfig() {
      console.log('Loading TURN config');
      if(this.connectionConfig === null) {
        var vm = this;
        axios.get('/api/config/get-turn', { withCredentials: true })
          .then((response) => {
            console.log("Config data: ", response.data);
            var peerConnectionConfig = { 
              iceServers: response.data,
            };
            vm.connectionConfig = peerConnectionConfig;
          })
          .catch(function () {
          });
      }
    },
    createPeerConnection(connectionId) {
      console.log("Peer connection config", this.connectionConfig);
      var connection = new RTCPeerConnection(this.connectionConfig);

      console.log("Add tracks to new connection!", this.groupStream.getAudioTracks());
      if (this.isServerSide) {
        console.log("Add groupStream tracks to new connection");
        this.groupStream.getAudioTracks().forEach((track) => {
          console.log("Add track to connection: ", track);
          var sender = connection.addTrack(track);
          var cid = this.getConnectionIdByTrack(track);
          if(cid !== '') {
            this.tracksRemoteConnections[cid].push({
              cid: connectionId,
              sender: sender
            });
          }
        });
      } else {
        console.log("Add myTrack to new connection");
        connection.addTrack(this.myTrack);
      }

      connection.onicecandidate = (event) => this.callbackIceCandidate(event, connection, connectionId);
      connection.oniceconnectionstatechange = (event) => this.callbackIceConnectionStateChanged(event, connection, connectionId);
      connection.onremovetrack = (event) => this.callbackRemoveTrack(connection, event);
      connection.onnegotiationneeded = (event) => this.callbackNegotiationNeeded(event, connection, connectionId);
      connection.ontrack = (event) => this.callbackTrack(event, connectionId);

      this.tracksRemoteConnections[connectionId] = [];

      return connection;
    },
    callbackIceCandidate (evt, connection, partnerClientId) {
      console.log("WebRTC: Ice Candidate callback");

      if (evt.candidate) {
          // Found a new candidate
          console.log('WebRTC: new ICE candidate');
          this.sendHubSignal(JSON.stringify({ "candidate": evt.candidate }), partnerClientId);
      } else {
          // Null candidate means we are done collecting candidates.
          console.log('WebRTC: ICE candidate gathering complete');
          this.sendHubSignal(JSON.stringify({ "candidate": null }), partnerClientId);
          this.updateChannelsCounter();
      }
    },
    callbackIceConnectionStateChanged(event, connection, connectionId) {
      var cid = connectionId; //this.getConnectionIdByConnection(connection);
      if(cid === '' || !this.connections[cid]) {
        console.log('Unknown connectionId, iceConnectionState:', connection.iceConnectionState);
        return;
      }
      console.log('ICE Connection state changed [' + cid  + ']:', connection.iceConnectionState);

      //Connected
      if(connection.iceConnectionState === 'connected') {
        if(!this.isServerSide) {
          wsconn.invoke("CallSuccess");
        }
      }

      //Disconnected
      if(connection.iceConnectionState === 'disconnected') {
        //
      }

      //Failed
      if(connection.iceConnectionState === 'failed') {
        //connection.restartIce();
        if(this.isServerSide) {
          const componentToRemove = document.querySelector(`[data-cid="${cid}"]`);
          if (componentToRemove)
            componentToRemove.remove();

          //Remove track from other connections
          if(this.tracksRemoteConnections[cid]) {
            console.log("Remove track from Connections: ", cid);
            for (const [index, value] of Object.entries(this.tracksRemoteConnections[cid])) {
              if(this.connections[value.cid]) {
                console.log("Removed track from Connections: ", cid, index);
                this.connections[value.cid].removeTrack(value.sender);
              }
            }
          }

          //Remove track grom groupstream
          if(this.tracksGroupStream[cid]) {
            console.log("Remove track from GroupStream");
            this.groupStream.removeTrack(this.tracksGroupStream[cid]);
          }
          
          if(this.connections[cid]) {
            console.log("Remove connection:", cid);
            delete this.connections[cid];
          }

          console.log("Trying to kick user:", cid);
          wsconn.invoke("Kick", cid);

          this.updateChannelsCounter();
        }
        else {
          this.clearAllPlayers();
        }
      }

    },
    callbackNegotiationNeeded (event, connection, connectionId) {
      if(!this.connections[connectionId])
        return;
      console.log("Negotiation needed with [" + connectionId +  "]: ", event);

      try {
        this.makingOffer = true;
        for (const [key, value] of Object.entries(this.connections)) {
          if (value === event.target && key !== this.moderatorConnectionId) {
            this.initiateOffer(key);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.makingOffer = false;
      }

    },
    callbackRemoveTrack(connection, event) {
      console.log("Remove track", event);
    },
    callbackTrack(event, connectionId) {
      console.log("Add new audio track TAG", event);

      var cid;
      var track = event.track;
      if(this.isServerSide)
        cid = connectionId; //this.getConnectionIdByConnection(event.target);
      else
        cid = track.id;
      console.log("TAG CID:", cid);

      const players = document.getElementById('pnlPlayers');
      const audioElement = document.createElement('audio');
      audioElement.id = "audioTrack_" + cid;
      audioElement.className = "webrtc-audio-player";
      audioElement.srcObject = new MediaStream([track]);
      audioElement.autoplay = true;
      audioElement.dataset.cid = cid;
      players.appendChild(audioElement);

      //Group stream
      this.groupStream.addTrack(track);
      if(this.isServerSide) {
        this.tracksGroupStream[cid] = track;
        if(this.streamSharing) {
          this.streamSharingPublisher.addTrack(track);
        }
      }
      this.updateChannelsCounter();

      //Add to current recording session
      if(this.ac != null && this.destination != null) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(track);
        const mediaStreamAudioSourceNode = new MediaStreamAudioSourceNode(this.ac, { mediaStream: mediaStream });
        mediaStreamAudioSourceNode.connect(this.destination);
      }

      console.log("Track has been added TAG", track);
    },
    clearAllPlayers() {
      console.log("Clear all players, clean connects.")
      var parent = document.getElementById('pnlPlayers');
      while (parent.firstChild) {
        parent.firstChild.remove();
      }
      this.connections = [];
      this.channelsCounter = 0;
      this.groupStream = new MediaStream();
    },
    getConnectionIdByConnection(connection) {
      for (const [key, value] of Object.entries(this.connections)) {
        if (value === connection)
            return key;
      }
      return "";
    },
    getConnectionIdByTrack(track) {
      try {
      for (const [key, value] of Object.entries(this.tracksGroupStream)) {
        console.log(key, value);
        if (value === track)
            return key;
      }
      }
      catch(e) { console.error(e); }
      return "";
    },
    applyToOldFromNew (connection, connectionId) {
      console.log("ConnectionId", connectionId);
      console.log("Receivers", connection.getReceivers());
      if(connection.getReceivers().length == 0)
        return;
      let track = connection.getReceivers()[0].track;

      console.log("Trying to add new tracks!", track);
      for (const [key, value] of Object.entries(this.connections)) {
          if (key !== connectionId) {
            console.log("Added to Old new track!");
            var sender = value.addTrack(track);
            this.tracksRemoteConnections[connectionId].push({
              cid: key,
              sender: sender
            });
            console.log("Tracks list", this.tracksRemoteConnections[connectionId], sender);
          }
      }
    },
    receivedSdpSignal (connection, partnerClientId, sdp) {

      var desc = new RTCSessionDescription(sdp);
      const offerCollision = desc.type === "offer" &&
        (this.makingOffer || connection.signalingState !== "stable");

      if (this.isServerSide && offerCollision) {
        console.log("Ignore receivedSdpSignal");
        return;
      }

      console.log('connection: ', connection);
      console.log('sdp: ', sdp);  
      console.log('WebRTC: called receivedSdpSignal && processing sdp signal');
      connection.setRemoteDescription(new RTCSessionDescription(desc))
      .then(() => {
          console.log('WebRTC: set Remote Description. Type: ' + connection.remoteDescription.type);
          if (connection.remoteDescription.type == "offer") {
              console.log('WebRTC: remote Description type offer');

              connection.createAnswer().then((desc) => {
                  console.log('WebRTC: create Answer...');
                  connection.setLocalDescription(desc)
                  .then(() => {
                      console.log('WebRTC: set Local Description...');
                      console.log('connection.localDescription: ', connection.localDescription);
                      //setTimeout(() => {
                        this.sendHubSignal(JSON.stringify({ "sdp": connection.localDescription }), partnerClientId);
                      //}, 0);
                  })
                  .catch(this.logError);
              }, this.logError);
          } else if (connection.remoteDescription.type == "answer") {
              console.log('WebRTC: remote Description type answer');
          }
      }).catch(this.logError);
    },
    receivedCandidateSignal(connection, partnerClientId, candidate) {
      console.log('WebRTC: adding full candidate');
      connection.addIceCandidate(new RTCIceCandidate(candidate), () => console.log("WebRTC: added candidate successfully"), () => console.log("WebRTC: cannot add candidate"));
    },

    //Invokes
    ifNeedWsconnSetup() {
      console.log("SignalR current state", wsconn.state);
      if(wsconn.state === 'Connected') {
        this.wsconnStop();
      }

      if(this.wsconnSetup === false) {
        console.log("Need to setup wsconn...");
        setupConnection(wsconn, this);
        this.wsconnSetup = true;
      }
    },
    wsconnStop() {
      setTimeout(() => {
        wsconn.stop().then(() => {
            console.log("SignalR stopped.");
          });
      }, 750);
    },
    createSession() {
      console.log('Creating session...');

      //One time setup
      this.ifNeedWsconnSetup();

      wsconn.start().then(() => {
        console.log("SignalR: Connected");

        this.groupStream = new MediaStream();
        this.groupStream.addTrack(this.myTrack);
        this.updateChannelsCounter();

        var id = this.$route.params.id;
        wsconn.invoke("Create", id)
        .then(() => {
          this.visibleModeratorsToolset = true;
          this.visibleConnection = true;
          this.$refs.pnlSend.connected = true;
          this.hasRecord = false;
        })
        .catch((error) => {
          console.error(error);
        });

      }).catch((error) => {
        console.error(error);
      });
    },
    stopSession() {
      if(this.streamSharing) {
        this.streamStopPublish();
      }
      this.stopAllConnections();
      this.connections = [];
      this.groupStream = null;
      this.visibleModeratorsToolset = false;
      this.visibleMembersToolset = false;
      this.visibleConnection = false;
      this.$refs.pnlSend.connected = false;
      this.$refs.postRecorder.soundRecStop();
      this.ac = null;
      this.destination = null;
      this.clearAllPlayers();
      this.streamSharingId = null;
      this.listenersCounter = 0;
      this.streamConfigAllowChat = true;
      this.streamConfigAllowDonations = false;
      this.wsconnStop();
    },
    joinSession() {
      console.log('Joining session...');

      //One time setup
      this.ifNeedWsconnSetup();

      wsconn.start().then(() => {
        console.log("SignalR: Connected");

        this.groupStream = new MediaStream();
        this.groupStream.addTrack(this.myTrack);
        this.updateChannelsCounter();

        var id = this.$route.params.session;
        wsconn.invoke("Join", id)
        .then(() => {
          this.visibleMembersToolset = true;
          this.visibleConnection = true;
          this.idSessionGroup = id;
          this.$refs.pnlSend.connected = true;
          this.callToModerator();
        })
        .catch((error) => {
          console.error(error);
        });

      }).catch((error) => {
        console.error(error);
      });
    },
    callToModerator () {
      wsconn.invoke('Call')
      .then(() => {
        this.hasCallProcess = true;
      })
      .catch((e) => {
        console.log(e);
      });
    },
    stopAllConnections () {
      if(this.connections) {
        for (const [key, value] of Object.entries(this.connections)) {
          if(key)
            value.close();
        }
      }
    },
    hangupCall () {
      if(wsconn)
        this.wsconnStop();
      this.stopAllConnections();
      this.connections = [];
      this.visibleModeratorsToolset = false;
      this.visibleMembersToolset = false;
      this.visibleConnection = false;
      this.hasCallProcess = false;
      this.$refs.pnlSend.connected = false;
      this.streamConfigAllowDonations = false;
      this.clearAllPlayers();
    },
    sendHubSignal (candidate, partnerClientId) {
      console.log('sendHubSignal: partner: ' + partnerClientId + ', candidate: ', candidate);
      wsconn.invoke('sendSignal', partnerClientId, candidate).catch(this.logError);
    },
    sendMessage(form) {
      var isGuest = false;
      wsconn.invoke("SendMessage", form.message, isGuest).catch((error) => {
        console.error(error);
      });
    },
    updateChannelsCounter() {
      var value = 0;
      if(this.groupStream && this.isServerSide)
        value = this.groupStream.getAudioTracks().length;
      if(!this.isServerSide && this.connections[this.moderatorConnectionId]) {
        var activeTracksCount = 1;
        this.connections[this.moderatorConnectionId].getReceivers().forEach(receiver => {
          if (receiver.track && !receiver.track.muted) {
            activeTracksCount++;
          }
        });
        value = activeTracksCount;
      }
      this.channelsCounter = value;
    },

    //Recorder
    recorderInterceptor() {
      this.ac = new AudioContext();
      this.destination = new MediaStreamAudioDestinationNode(this.ac);

      this.groupStream.getAudioTracks().forEach((track) => {
          const mediaStream = new MediaStream();
          mediaStream.addTrack(track);
          const mediaStreamAudioSourceNode = new MediaStreamAudioSourceNode(this.ac, { mediaStream: mediaStream });
          mediaStreamAudioSourceNode.connect(this.destination);
      });

      var merged = this.destination.stream;
      this.$refs.postRecorder.createMediaRecorder(merged);
    },
    recorderModeChanged(state) {
      this.recorderState = state;
      if(wsconn.state === 'Connected') {
        if(state === 'on') {
          wsconn.invoke('RecStart').catch(this.logError);
        }
        else {
          wsconn.invoke('RecStop').catch(this.logError);
        }
      }
    },
    sourceSelected(label) {
      this.deviceLabel = label;
    },
    speakerModeChanged(value) {
      if(this.visibleConnection && this.speakersCounter > 1 && wsconn.state === 'Connected')
        wsconn.invoke('ActiveSpeaker', value).catch(this.logError);
    },
    speakerMicStateChanged(value) {
      if(this.visibleConnection && wsconn.state === 'Connected')
        wsconn.invoke('MicState', value).catch(this.logError);
    },

    //Create post
    sendCreatePostForm() {
      this.buttonCreateDisabled = true;
      this.buttonCreateText = 'Uploading...';

      //blob
      var blob = null;
      var duration = 0;
      if (this.$refs.postRecorder != null && this.$refs.postRecorder.resultBlob != null) {
        blob = this.$refs.postRecorder.resultBlob;
        duration = this.$refs.postRecorder.audioDuration;
      }

      console.log("Send Create post form...");
      console.log("Duration: ", duration);
      console.log("BLOB: ", blob); 

      if(duration > 0 && blob !== null) {
        var post = {
          type: 3, //LIVE
          duration: duration,
          title: this.title,
          message: this.message,
          embed: null,
          isIgnored: false,
        };
        this.$refs.pnlUploader.post(blob, this.previewImage, post);
      } else {
        this.uploaderPostFailed();
      }

    },
    uploaderPostSuccess(id) {
      console.log("Post has been created: ", id);
      this.uploadReset();
      this.title = '';
      this.message = '';
      this.$refs.postRecorder.soundDelete();

      wsconn.invoke('PostCreated', id)
        .then(() => {})
        .catch(this.logError);
    },
    uploaderPostFailed() {
      this.uploadReset();
      console.log("Post has been failed.");
    },
    uploaderUploadSuccess() {
      console.log("Upload has been completed.");
    },
    uploaderUploadFailed() {
      this.uploadReset();
      console.log("Upload has been failed.");
    },
    uploadReset() {
      this.buttonCreateDisabled = false;
      this.buttonCreateText = 'Upload';
    },

    loadingEpisode() {
      axios.get('/api/episode/simple/' + this.$route.params.id, { withCredentials: true })
        .then((response) => {
          this.episode = response.data.episode;
        })
        .catch(function () {
        });
    },

    //Stream sharing
    streamStartPublish() {
      this.streamSharing = true;
      this.streamSharingId = this.idSessionGroup;
      this.streamSharingUrl = process.env.VUE_APP_HOST_URL + 'stream/' + this.episode.id + '/' + this.streamSharingId;
      this.streamSharingWhip = process.env.VUE_APP_MEDIAMTX_URL + this.streamSharingId + '/whip';
      console.log('Stream sharing URL', this.streamSharingUrl);
      console.log('Stream WHIP URL', this.streamSharingWhip);

      var config = {
        whipUrl: this.streamSharingWhip,
      };
      this.streamSharingPublisher = new streamPublisher(config);
      this.streamSharingPublisher.setStream(this.groupStream);
      this.streamSharingPublisher.publish();

      wsconn.invoke('PublishStream', this.streamSharingUrl)
          .then(() => {})
          .catch(() => {});
    },
    streamStopPublish() {
      this.streamSharing = false;
      this.streamSharingPublisher.stop();

      wsconn.invoke('StopStream')
          .then(() => {})
          .catch(() => {});
    },
    streamAllowChat() {
      console.log('Allow chat: ', this.streamConfigAllowChat);
      var value = this.streamConfigAllowChat ? '1' : '0';
      wsconn.invoke('Config', 'allow_guest_chat=' + value)
          .then(() => {})
          .catch(() => {});
    },
    streamAllowDonations() {
      console.log('Allow donations: ', this.streamConfigAllowDonations);
      var value = this.streamConfigAllowDonations ? '1' : '0';
      wsconn.invoke('Config', 'allow_donations=' + value)
          .then(() => {})
          .catch(() => {});
    },
    copyStreamLink() {
      try {
        write(this.streamSharingUrl);
      }
      catch(e) {
        console.error(e);
      }
    },
    profileAction(id, status) {
      console.log("Chat/Profile action: ", id, status);
      wsconn.invoke('Config', 'profile_action_' + status + '=' + id)
          .then(() => {})
          .catch(() => {});
    },

    //Common
    sendSystemMessageToChat(message, attach = false) {
      var time = new Date().toLocaleTimeString();
        var m = {
          align: 'left',
          name: '',
          image: '',
          message: message,
          time: time,
          system: true,
        }
        this.$refs.pnlMessages.addMessage(m, attach, true);
    },
    logError(e) {
      console.error("ERROR wsconn:", e);
    },
  },
  mounted() {
    this.loadingEpisode();
    this.getConnectionConfig();
  },
  computed: {
  },
};
</script>

<template>
  <div class="row">

    <div class="col-12 col-md-5 col-sm-12 col-xs-12">

      <Title :title="episode.title" :short="episode.short" :url="episode.urlAvatar" :is-server-side="isServerSide"></Title>

      <div class="card card-primary" v-if="!visibleConnection">
        <div class="card-footer">
          <div class="text-center">
            <button class="btn btn-primary" @click="createSession" v-if="isServerSide" :disabled="!hasMediaAccess">Create Live-room</button>
            <button class="btn btn-primary" @click="joinSession" v-if="!isServerSide" :disabled="!hasMediaAccess">Join Live-room</button>
          </div>
        </div>
      </div>

      <div class="card card-primary" v-if="visibleModeratorsToolset">
        <div class="card-footer">
          <div class="text-center">
            <button class="btn btn-primary" @click="stopSession" v-if="visibleModeratorsToolset && isServerSide" :disabled="recorderState==='on' || hasRecord">Stop Live</button>
          </div>
        </div>
      </div>

      <div class="card card-primary" v-if="visibleMembersToolset">
        <div class="card-footer">
          <div class="text-center">
            <button class="btn btn-primary" @click="callToModerator" v-if="!isServerSide && false" :disabled="hasCallProcess">Call</button>
            <button class="btn btn-primary ms-2" @click="hangupCall" v-if="!isServerSide" :disabled="!hasCallProcess">Hang-up</button>
            <b-button variant="primary" class="btn-label waves-effect waves-light ms-2" v-if="listenersCounter > 0">
              <i class="ri-broadcast-line label-icon align-middle fs-16 me-2"></i> {{ listenersCounter }}
            </b-button>
          </div>
        </div>
      </div>

      <Profiles ref="pnlProfiles"></Profiles>

      <div class="card shadow-none">
        <div class="card-header ps-0 pt-0">
          <h2 class="card-title mb-0 flex-grow-1">{{ deviceLabel ? this.deviceLabel : 'Recorder' }}</h2>
        </div>
        <div class="card-body ps-lg-0 pe-0">
          <Recorder ref="postRecorder" @recorderMode="recorderModeChanged" @prerec-click="recorderInterceptor" @stream-ready="readyStreamAccess" @record-ready="readyRecordFile" @source-selected="sourceSelected" @speaker-mode="speakerModeChanged" @speaker-micstate="speakerMicStateChanged" :buttonRecVisible="visibleModeratorsToolset" :buttonSourceVisible="!visibleConnection" :buttonMuteVisible="true" trimmerBackModal=".recorderModal2"></Recorder>

          <div class="row mt-1 g-3" v-if="hasRecord">
            <div class="col-12">
              <div>
                <label for="title" class="form-label">Title</label>
                <input type="text" class="form-control" id="title" placeholder="Your title" v-model="title" v-on:keydown.enter.prevent="preventEnterKey">
              </div>
            </div>
            <div class="col-12 mt-3">
              <div>
                <label for="message" class="form-label">Comment</label>
                <textarea id="message" class="form-control" rows="2" placeholder="Your comment" v-model="message"></textarea>
              </div>
            </div>
            <div class="col-12 mt-3">
              <Uploader ref="pnlUploader" @post-success="uploaderPostSuccess" @post-error="uploaderPostFailed" @upload-success="uploaderUploadSuccess" @upload-error="uploaderUploadFailed" />
              <button type="button" @click="sendCreatePostForm" class="btn btn-primary" v-bind:disabled="buttonCreateDisabled">{{ buttonCreateText }}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="card shadow-none" v-if="visibleConnection && isServerSide">
        <div class="card-header ps-0 pt-0">
          <h2 class="card-title mb-0 flex-grow-1">Share stream</h2>
        </div>
        <div class="card-body ps-lg-0 pe-0">
          <button class="btn btn-primary me-2" @click="streamStartPublish" v-if="!streamSharing">Create stream link</button>
          <button class="btn btn-primary" @click="streamStopPublish" v-if="streamSharing">Stop stream sharing</button>
          <b-button variant="light" class="btn-label waves-effect waves-light ms-2" v-if="streamSharing">
            <i class="ri-broadcast-line label-icon align-middle fs-16 me-2"></i> {{ listenersCounter }}
          </b-button>
          <div class="mt-2" v-if="streamSharing">
            <div class="input-group">
              <input type="text" class="form-control readonly" readonly="readonly" placeholder="" aria-label="" aria-describedby="btcShareLinkCopy" :value="streamSharingUrl">
              <button class="btn btn-primary" type="button" id="btcShareLinkCopy" @click="copyStreamLink">
                <span class="ri-file-copy-line"></span>
              </button>
            </div>
            <div class="form-check mt-2 mb-2">
              <input class="form-check-input" type="checkbox" id="chkAllowChat" v-model="streamConfigAllowChat" @change="streamAllowChat">
              <label class="form-check-label" for="chkAllowChat">
                Allow chat
              </label>
            </div>
            <div class="form-check mb-2">
              <input class="form-check-input" type="checkbox" id="chkAllowDonations" v-model="streamConfigAllowDonations" @change="streamAllowDonations">
              <label class="form-check-label" for="chkAllowDonations">
                Allow donations
              </label>
            </div>
          </div>
        </div>
      </div>

      <Donate ref="pnlDonations" v-if="streamConfigAllowDonations" :session="this.idSessionGroup" :episode="this.episode.id"></Donate>

    </div>

    <div class="col-12 col-md-7 col-sm-12 col-xs-12">
      <div class="d-lg-flex">
        <div class="w-100 position-relative">
          <div class="user-chat position-relative">

            <Messages ref="pnlMessages" :imModerator="isServerSide" @profile-click="profileAction"></Messages>
            <Send ref="pnlSend" @send-message="sendMessage"></Send>

          </div>
        </div>
      </div>
    </div>

  </div>
  <div id="pnlPlayers"></div>
</template>
