<script>
import Layout from "../../layouts/main.vue";
import Live from "./Live.vue";

export default {
  data() {
    return {
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    Layout,
    Live
  },
  mounted() {
    this.$refs.pnlLive.isServerSide = false;
    this.$refs.pnlLive.accessMedia(false);
  },
  methods: {
  }
};
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-body">
        <Live ref="pnlLive"></Live>
      </div>
    </div>
  </Layout>
</template>
