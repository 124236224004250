<script>
import { createAudioMeter } from "./js/volume-meter";

export default {
  emits: ["meter-level"],
  data() {
    return {
        audioContext: null,
        canvasContext: null,
        mediaStreamSource: null,
        rafID: null,
        canvasWidth: 0,
        canvasHeight: 0,
        intervalId: null,
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  mounted() {
  },
  unmounted() {
    this.stopUpdate();
  },
  methods: {
    setupAudioContext(stream) {
      this.canvasContext = this.$refs.meterLevel.getContext("2d");
      this.canvasWidth = this.canvasContext.canvas.width;
      this.canvasHeight = this.canvasContext.canvas.height;

      this.audioContext = new AudioContext();
      this.mediaStreamSource = this.audioContext.createMediaStreamSource(stream);
      this.meter = createAudioMeter(this.audioContext);
      this.mediaStreamSource.connect(this.meter);

      this.drawLoop();
      this.levelMeterThread();
    },
    levelMeterThread() {
      var vm = this;
      this.intervalId = setInterval(function() {
        if(!vm.meter)
          return;
        vm.$emit('meter-level', vm.meter.volume);
      }, 250);
    },
    drawLoop() {
        if(!this.meter)
          return;
        const newHeight = this.meter.volume * this.canvasHeight * 2.25;

        // clear the background
        this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        this.canvasContext.beginPath();

        // draw a bar based on the current volume
        if (this.meter.checkClipping())
          this.canvasContext.fillStyle = "#ed5e5e";
        else
          this.canvasContext.fillStyle = "#13c56b";

        this.canvasContext.roundRect(0, this.canvasHeight - newHeight, this.canvasWidth, this.canvasHeight, 4);
        this.canvasContext.closePath();
        this.canvasContext.fill();
        
        // set up the next visual callback
        this.rafID = window.requestAnimationFrame(this.drawLoop);
    },
    restoreUpdate() {
      this.rafID = window.requestAnimationFrame(this.drawLoop);
    },
    stopUpdate() {
      if(this.rafID)
        window.cancelAnimationFrame(this.rafID);
      if(this.intervalId)
        clearInterval(this.intervalId);
    }
  }
};
</script>

<template>
    <div class="recorder-level">
        <canvas ref="meterLevel" width="10" height="39"></canvas>
    </div>
</template>
