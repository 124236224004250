<script>
import { nextTick } from 'vue'
import Level from "../recorder/Level.vue";
import Trimmer from "../recorder/Trimmer.vue";

import AudioRecorder from 'audio-recorder-polyfill';
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder'
AudioRecorder.encoder = mpegEncoder
AudioRecorder.prototype.mimeType = 'audio/mpeg'
window.MediaRecorder = AudioRecorder;

const options = {
    audio: {
        autoGainControl: false,
        echoCancellation: true,
        noiseSuppression: false,
        channelCount: 2,
        sampleRate: 44100,
        sampleSize: 16,
        volume: 1.0,
        latency: 0
    },
    video: false
};

export default {
    emits: ["recorderMode", "prerec-click", "stream-ready", "record-ready", "source-selected", "speaker-mode", "speaker-micstate"],
    name: "Recorder",
    data() {
        return {
            state: 'new',
            classRec: 'ri-checkbox-blank-circle-fill',
            classRecStop: 'ri-stop-fill',
            classRecPause: 'ri-pause-line',
            classPlay: 'ri-play-fill',
            classPlayPause: 'ri-pause-fill',
            classMuteOn: 'ri-mic-off-line',
            classMuteOff: 'ri-mic-line',
            buttonRecClass: 'ri-checkbox-blank-circle-fill',
            buttonMuteClass: 'ri-mic-line',
            buttonRecDisabled: true,
            audioPlayerVisible: false,
            audioPlayerClass: 'ri-play-fill',
            hasPermissions: false,
            startRecTime: null,
            recorderRedTicker: true,
            audioUrl: 'none',
            audioDuration: 0,
            audioDurationPause: 0,
            mediaRecorder: null,
            recorder: null,
            sound: new Audio(),
            audioCtx: null,
            chunks: [],
            resultBlob: null,
            timer: null,
            audioInputs: [],
            mediaDefaultInputDevicesId: null,
            stream: null,
            uid: '',
            levelMeterTime: 0,
            iSpeakNow: false,
        }
    },
    props: {
        buttonRecVisible: {
            default: true,
            type: Boolean
        },
        buttonSourceVisible: {
            default: true,
            type: Boolean
        },
        buttonMuteVisible: {
            default: false,
            type: Boolean
        },
        trimmerBackModal: {
            default: '',
            type: String
        }
    },
    beforeRouteUpdate: function (to, from, next) {
        console.log("Change", next);
        if (this.confirmStay())
            return false;
    },
    created: function () {
        window.addEventListener('beforeunload', this.beforeWindowUnload);
    },
    beforeUnmount() {
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
    },
    components: {
        Level,
        Trimmer,
    },
    mounted() {
        this.uid = 'recorder_' + Math.random().toString(36).substring(2, 15);
    },
    methods: {
        confirmStay() {
            return this.resultBlob !== null || this.buttonRecClass === this.classRecStop;
        },
        beforeWindowUnload(e) {
            if (this.confirmStay()) {
                e.preventDefault();
                e.returnValue = '';
            }
        },
        accessToMedia() {
            if (this.mediaDefaultInputDevicesId) {
                options.audio.deviceId = this.mediaDefaultInputDevicesId;
            }
            navigator.mediaDevices.getUserMedia(options).then(this.onSuccess, this.onError);
        },
        soundMute() {
            var track = this.stream.getAudioTracks()[0];
            if (track) {
                if (this.buttonMuteClass === this.classMuteOn) {
                    this.buttonMuteClass = this.classMuteOff;
                    this.$emit('speaker-micstate', true);
                    track.enabled = true;
                }
                else {
                    this.buttonMuteClass = this.classMuteOn;
                    this.$emit('speaker-micstate', false);
                    track.enabled = false;
                }
            }
        },
        soundRec() {
            this.$emit('prerec-click');
            this.state = 'rec';
            setTimeout(() => {
                this.buttonRecClass = this.classRecStop;
                this.soundDelete();
                this.recorderTimer();
                this.$emit('recorderMode', 'on');
                this.recorder.start();
            }, 200);
        },
        soundRecPause() {
            if (this.classRecPause === 'ri-pause-line') {
                this.classRecPause = 'ri-checkbox-blank-circle-fill';
                this.recorderRedTicker = false;
                this.recorder.pause();

                var delta = Date.now() - this.startRecTime;
                this.audioDurationPause += Math.floor(delta / 1000);
            }
            else {
                this.classRecPause = 'ri-pause-line';
                this.startRecTime = Date.now();
                setTimeout(() => {
                    this.recorder.resume();
                }, 200);
            }
        },
        soundRecStop() {
            var hasRecord = this.state === 'rec';
            this.buttonRecClass = this.classRec;
            this.classRecPause = 'ri-pause-line';
            //this.audioDuration = 0;
            //this.audioDurationPause = 0;
            this.recorderTimerStop();
            this.recorder.stop();
            this.state = 'new';
            this.audioPlayerVisible = hasRecord;
            this.$emit('recorderMode', 'off');
            if (hasRecord) {
                this.$emit('record-ready', true);
            }
        },
        soundPlay() {
            if (this.audioPlayerClass === this.classPlay) {
                this.audioPlayerClass = this.classPlayPause;
                this.sound.play();
            } else {
                this.audioPlayerClass = this.classPlay;
                this.sound.pause();
            }
        },
        soundDelete() {
            this.audioPlayerVisible = false;
            this.audioPlayerClass = this.classPlay;
            if (this.sound != null)
                this.sound.pause();
            this.chunks = [];
            this.resultBlob = null;
            this.audioDuration = 0;
            this.audioDurationPause = 0;
            this.$emit('record-ready', false);
        },
        soundDownload() {
            var podcastDate = new Date();
            const ticksShift = 621355968000000000;
            var ticks = ticksShift + (podcastDate.getTime() * 10000);
            var filename = "podcast_" + ticks + ".mp3";
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(this.resultBlob, filename);
            } else {
                const a = document.createElement('a');
                document.body.appendChild(a);
                const url = window.URL.createObjectURL(this.resultBlob);
                a.href = url;
                a.download = filename;
                a.click();
                setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                }, 0);
            }
        },
        playerStopped() {
            this.audioPlayerClass = this.classPlay;
            this.sound.pause();
        },
        async recorderTimer() {
            var vm = this;
            this.startRecTime = Date.now();
            this.audioDuration = 0;
            await nextTick();
            this.timer = setInterval(function () {
                if (vm.state === 'rec' && vm.classRecPause === 'ri-pause-line') {
                    var delta = Date.now() - vm.startRecTime;
                    vm.audioDuration = vm.audioDurationPause + Math.floor(delta / 1000);
                    vm.recorderRedTicker = !vm.recorderRedTicker;
                }
            }, 500);
        },
        recorderTimerStop() {
            if (this.timer)
                clearInterval(this.timer);
        },
        onSuccess(stream) {
            this.stream = stream;
            this.buttonRecDisabled = true;
            this.buttonRecAccessDisabled = false;
            this.hasPermissions = true;
            this.buttonRecDisabled = false;
            this.createMediaRecorder(stream);
            this.updateLevelMeter(stream);
            this.setupAudioInputs(stream);
            this.$emit('stream-ready', stream);
        },
        onError() {
            this.buttonRecDisabled = true;
        },
        onStopRecorder() {
        },
        onStartRecorder() {
        },
        onDataRecorder() {
        },

        //Custom media recorder (from Live component)
        createMediaRecorder(stream) {
            const options = {
                audioBitsPerSecond: 44100,
            };

            this.recorder = new MediaRecorder(stream, options);
            this.recorder.addEventListener('dataavailable', e => {
                this.resultBlob = e.data;
                this.audioUrl = window.URL.createObjectURL(e.data);
                this.updateSound();
            });
        },
        updateSound() {
            this.sound = new Audio(this.audioUrl);
            this.sound.onended = this.playerStopped;
            this.sound.load();

            var vm = this;
            this.sound.addEventListener("loadeddata", function () {
                vm.audioDuration = this.duration;
            });
        },

        //Trimmer
        updateAudioTrimmer(model) {
            this.resultBlob = model.blob;
            this.audioUrl = model.url;
            this.updateSound();
            console.log("Update!", model.url);
        },

        //Level meter
        updateLevelMeter(stream) {
            this.$refs.levelMeter.setupAudioContext(stream);
        },
        stopLevelMeterUpdate() {
            this.$refs.levelMeter.stopUpdate();
        },
        restoreLevelMeterUpdate() {
            this.$refs.levelMeter.restoreUpdate();
        },
        levelMeterChanged(value) {
            const threshold = 0.095;
            const thresholdMs = 2250;
            const currentTime = Date.now();

            const isSpeakingLevel = value >= threshold;
            if(isSpeakingLevel) {
                if(!this.iSpeakNow) {
                    this.$emit('speaker-mode', true);
                }
                this.iSpeakNow = true;
                this.levelMeterTime = currentTime;
            }

            if (currentTime - this.levelMeterTime >= thresholdMs && !isSpeakingLevel) {
                if(this.iSpeakNow) {
                    this.$emit('speaker-mode', false);
                }
                this.iSpeakNow = false;
            }
        },

        async demoTest() {
            var url = "http://192.168.1.19:9000/esycast-bucket/podcasts/result_a32963f5-2bbd-437d-af06-9e6ac454ad7e___638458788659665032.mp3";
            var vm = this;
            this.resultBlob = await fetch(url).then(r => {
                vm.state = 'new';
                vm.audioDuration = 100;
                vm.buttonRecClass = this.classRec;
                vm.$emit('recorderMode', 'off');
                vm.$emit('record-ready', true);

                console.log("DEMO TEST DOWNLOADED");
                return r.blob();
            });
        },

        //Audio inputs
        setupAudioInputs(stream) {
            var list = stream.getTracks().map((track) => track.getSettings().deviceId);
            this.mediaDefaultInputDevicesId = this.getDefaultInputDeviceId(list);
            console.log('Default input device Id: ', this.mediaDefaultInputDevicesId);
            this.getInputMediaDevices().then((microphones) => {
                this.audioInputs = microphones;
                console.log("Microphones", microphones);

                if (this.mediaDefaultInputDevicesId) {
                    const targetMicrophone = microphones.find(microphone => microphone.deviceId === this.mediaDefaultInputDevicesId);
                    if (targetMicrophone) {
                        console.log("Label for microphone with deviceId:", targetMicrophone.label);
                        this.$emit('source-selected', targetMicrophone.label);
                    }
                }
            });
        },
        getDefaultInputDeviceId(list) {
            if (list && list.length === 1) {
                const entries = Object.entries(list);
                return entries[0][1];
            }
            return '';
        },
        audioInputChanged(deviceId, label) {
            this.$emit('source-selected', label);
            this.mediaDefaultInputDevicesId = deviceId;
            this.accessToMedia();
            console.log('Default input device: ', this.mediaDefaultInputDevicesId);
        },
        async getInputMediaDevices() {
            return await this.getMediaDevices("audioinput");
        },
        async getOutputMediaDevices() {
            return await this.getMediaDevices("audiooutput");
        },
        async getMediaDevices(kind) {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const kindList = devices.filter(device => device.kind === kind);
                return kindList;
            } catch (error) {
                console.error('Error enumerating devices [' + kind + ']:', error);
                return [];
            }
        }
    },
    unmounted() {
        this.mediaRecorder = null;
        this.resultBlob = null;
        if (this.recorder != null)
            this.recorder.stream.getTracks().forEach(i => i.stop());
        if (this.sound != null)
            this.sound.pause();
    },
    computed: {
        getModalClassForTrimmer: function () {
            if (this.trimmerBackModal === '.recorderModal1')
                return "trimmerModal1";
            else
                return "trimmerModal2";
        },
        getDurationFromSeconds: function () {
            if (this.audioDuration === null ^ this.audioDuration === 0)
                return '00:00';
            if (this.audioDuration >= 3600)
                return new Date(this.audioDuration * 1000).toISOString().substring(11, 19);
            else
                return new Date(this.audioDuration * 1000).toISOString().substring(14, 19);
        }
    }
}
</script>

<template>
    <div class="btn-group" :class="{ 'me-2': buttonRecVisible || buttonSourceVisible }">
        <button type="button" class="btn btn-danger" v-bind:disabled="buttonRecDisabled" @mouseup="soundRec"
            v-if="state === 'new' && buttonRecVisible">
            <span v-bind:class="buttonRecClass"></span>
        </button>
        <button type="button" class="btn btn-danger" @mouseup="soundRecPause" v-if="state === 'rec'">
            <span v-bind:class="classRecPause"></span>
        </button>
        <button type="button" class="btn btn-danger" @mouseup="soundRecStop" v-if="state === 'rec'">
            <span class="ri-stop-fill"></span>
        </button>
        <button type="button" class="btn btn-danger" v-bind:disabled="!audioPlayerVisible" data-bs-toggle="modal"
            :data-bs-target="'.' + getModalClassForTrimmer" :resultblob="resultBlob" :resultbloburl="this.audioUrl"
            v-if="state === 'new' && audioPlayerVisible">
            <span class="ri-scissors-2-line"></span>
        </button>
        <button class="btn btn-danger dropdown-toggle-split dropdown-toggle" type="button" data-bs-toggle="dropdown"
            aria-expanded="false" ref="selectAudioInput" :disabled="buttonRecClass === classRecStop"
            v-if="state === 'new' && buttonSourceVisible">
            <div class="btn-content"><span class="visually-hidden">Toggle dropdown</span></div>
            <span class="me-1">Source</span>
        </button>
        <button class="btn btn-secondary" @click="demoTest" v-if="false">TMP</button>
        <ul class="dropdown-menu dropdown-menu-md p-4" role="menu" v-if="state === 'new' && buttonSourceVisible">
            <p class="text-muted">Select audio input device:</p>
            <div class="form-check mb-2" v-for="mic in audioInputs" :key="mic.deviceId">
                <input class="form-check-input" type="radio" name="micAudioInput"
                    :id="'mic_' + this.uid + '_' + mic.deviceId"
                    :checked="this.mediaDefaultInputDevicesId === mic.deviceId"
                    @change="audioInputChanged(mic.deviceId, mic.label)">
                <label class="form-check-label" :for="'mic_' + this.uid + '_' + mic.deviceId">{{ mic.label }}</label>
            </div>
        </ul>
    </div>

    <button class="btn btn-danger me-2" type="button" :disabled="buttonRecDisabled"
        v-if="buttonMuteVisible && !buttonSourceVisible" @click="soundMute">
        <span :class="buttonMuteClass"></span>
    </button>

    <Level ref="levelMeter" @meter-level="levelMeterChanged"></Level>

    <button type="button" class="btn btn-primary ms-2" v-if="audioPlayerVisible" @click="soundPlay">
        <span v-bind:class="audioPlayerClass"></span>
    </button>
    <button type="button" class="btn btn-primary ms-2" v-if="audioPlayerVisible" @click="soundDownload">
        <span class="ri-download-line"></span>
    </button>
    <button type="button" class="btn btn-danger ms-2" v-if="audioPlayerVisible" @click="soundDelete">
        <span class="ri-delete-bin-2-line"></span>
    </button>
    <!-- <span class="ms-2" v-if="audioDuration !== 0">{{ this.getDurationFromSeconds }}</span> -->
    <b-button variant="light" class="position-relative ms-2" v-if="audioDuration !== 0">
        {{ this.getDurationFromSeconds }}
        <span v-show="recorderRedTicker" class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
            <span class="visually-hidden"></span>
        </span>
    </b-button>
    <Trimmer :modalClassToBack="trimmerBackModal" :modalFormId="getModalClassForTrimmer" :resultBlob="resultBlob" :resultBlobUrl="this.audioUrl" @update-audio="updateAudioTrimmer"></Trimmer>
</template>
