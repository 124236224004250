<script>
import axios from 'axios';
import Bottleneck from "bottleneck";

const limiter = new Bottleneck({
  maxConcurrent: 5,
});

export default {
  emits: ["upload-error", "upload-success", "post-error", "post-success"],
  data() {
    return {
        blob: null,
        uploadId: null,
        url: null,
        etags: [],
        percentValue: 0,
        percentMax: 100,
        chunkSize: 5242880,
        totalChunks: 0,
        uploadedChunks: 0,
        hasError: false,
        showProgress: false,
        animatedUiUxBuffer: 20,
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  mounted() {
  },
  methods: {
    clear() {
      this.blob = null;
      this.post = null;
      this.preview = null;
      this.uploadId = null;
      this.percentValue = 0;
      this.totalChunks = 0;
      this.uploadedChunks = 0;
      this.etags = [];
      this.hasError = false;
      this.showProgress = false;
    },
    post(blob, preview, post) {
      this.clear();

      this.blob = blob;
      this.preview = preview;
      this.post = post;

      if(blob.size > this.chunkSize * 2) {
        console.log("Huge uploader");
        this.showProgress = true;
        this.upload();
      } else {
        console.log("Small uploader");
        this.create(true, null);
      }
    },
    create(simple, url) {
      console.log('Uploading post: ', this.post);
      console.log('Simple or URL: ', simple, url);

      let formData = new FormData();
      if(simple)
        formData.append('record', this.blob);
      else
        formData.append('url', url);

      formData.append('image', this.preview);
      formData.append('type', this.post.type);
      formData.append('duration', this.post.duration);
      formData.append('title', this.post.title);
      formData.append('message', this.post.message);
      formData.append('embed', this.post.embed);
      formData.append('isIgnored', this.post.isIgnored);

      const vm = this;
      axios.post('/api/post/create/' + this.$route.params.id, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (e) {
          vm.$emit('post-success', e.data.id);
          vm.percentValue = vm.percentMax;
        }).catch(function () {
          vm.$emit('post-error');
      });
    },
    upload() {
      this.id = this.$route.params.id;
      console.log("Starting upload id_episode: ", this.id);
      console.log("Blob size: ", this.blob.size);

      const formData = new FormData();
      formData.append('id_episode', this.id);

      var vm = this;
      axios.post('/api/upload/create', formData, { withCredentials: true }).then((e) => {
        vm.processing(e);
      }).catch(error => {
        console.log("ERROR Create", error);
        vm.hasError = true;
      });
    },
    processing(e) {
      console.log("Upload id: ", e.data.uploadId);
      console.log("Filepath URL: ", e.data.url);
      this.uploadId = e.data.uploadId;
      this.url = e.data.url;
      this.totalChunks = Math.ceil(this.blob.size / this.chunkSize);
      this.percentMax = this.totalChunks + this.animatedUiUxBuffer;

      this.$nextTick(() => {
        this.percentValue = this.animatedUiUxBuffer;
      });

      for (let i = 0; i < this.totalChunks; i++) {
        if(this.hasError)
          break;
      
        console.log('Chunk, from: ', i, this.totalChunks);
        limiter.schedule(() => this.uploadChunk(i))
          .then(() => {
            this.uploadedChunks++;
            this.percentValue++;
            console.log("Check final part. Uploaded, Total: ", this.uploadedChunks, this.totalChunks);
            if (this.uploadedChunks === this.totalChunks) {
              this.uploadFinalize();
            }
          }).catch(error => {
            console.log("Upload chunk error: ", error);
            this.hasError = true;
            this.$emit('upload-error');
            this.abort();
          });

      }

      if(this.hasError)
        this.abort();
    },
    uploadChunk(chunkIndex) {
      return new Promise((resolve, reject) => {
        console.log("Uploading chunk #", chunkIndex);
        const start = chunkIndex * this.chunkSize;
        const end = Math.min(start + this.chunkSize, this.blob.size);
        const chunk = this.blob.slice(start, end);

        const formData = new FormData();
        formData.append('uploadid', this.uploadId);
        formData.append('fileChunk', chunk);
        formData.append('chunkIndex', chunkIndex);
        formData.append('url', this.url);
        formData.append('id_Episode', this.id);
        
        axios.post('/api/upload/chunk', formData, { withCredentials: true }, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        }).then((e) => {
          this.etags.push(e.data.partNumber + '_' + e.data.eTag);
          console.log("Chunk uploaded successful #, data: ", chunkIndex, e);
          resolve();
        }).catch(error => {
          console.log("Chunk uploaded failure: ", chunkIndex);
          reject(error);
        });
      });
    },
    uploadFinalize() {
      console.log("Finalize upload");
      
      const formData = new FormData();
      formData.append('uploadid', this.uploadId);
      formData.append('url', this.url);
      formData.append('id_Episode', this.id);
      formData.append('etags', this.etags);

      var vm = this;
      axios.post('/api/upload/complete', formData, { withCredentials: true }).then((e) => {
        console.log("Finalized! Content url: ", e.data);
        vm.percentValue = vm.percentMax;
        vm.create(false, e.data);
        vm.$emit('upload-success');
      }).catch(error => {
        console.log("ERROR Finalize", error);
        vm.hasError = true;
        vm.$emit('upload-error');
        vm.abort();
      });
    },
    abort() {
      this.percentValue = 0;

      const formData = new FormData();
      formData.append('url', this.url);

      axios.post('/api/upload/abort/' + this.uploadId, formData, { withCredentials: true }).then((e) => {
        console.log("Upload aborted: ", e);
      }).catch(error => {
        console.log("Upload abort error: ", error);
      });
    },
  }
};
</script>

<template>
  <div class="mb-3" v-if="showProgress">
    <b-progress :value="percentValue" :max="percentMax" striped animated></b-progress>
  </div>
</template>
